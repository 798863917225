
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import useTranslation from 'next-translate/useTranslation'

import Error from 'components/elements/Error'
import withPageStaticProps from 'utils/next/withPageStaticProps'

const TAG = 'Custom404Page'

const Custom404Page: NextPageWithLayout = () => {
  const { t } = useTranslation()

  return <Error buttonText={t('pages.404.button')} title={t('pages.404.title')} />
}

Custom404Page.displayName = TAG

export default Custom404Page

 const _getStaticProps = withPageStaticProps(void 0, { isErrorPage: true })


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  